<template>
  <div class="coaches__cardsList">
    <div class="coaches__card" v-for="coach in coaches" :key="coach.id">
      <div class="coaches__card-img__wrapper">
        <router-link :to="`/team/${coach.path.split('/')[2]}`">
          <img :src="`https://samokat-school.chickenkiller.com/images/Coaches/${coach.avatar}`" :alt="coach.name" />
        </router-link>
        <router-link class="coaches__btn" :to="coach.path">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none">
            <circle cx="21" cy="21" r="15" fill="#70C1FF" transform="rotate(-34.543 21.536 21.504)" />
            <path
              fill="#fff"
              d="M29.23 22.162a.93.93 0 0 0 0-1.314l-5.916-5.915A.93.93 0 1 0 22 16.247l5.258 5.258L22 26.763a.93.93 0 0 0 1.314 1.314l5.915-5.915Zm-14.73.272h14.072v-1.858H14.5v1.858Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18.361" cy="18.4382" r="12.5733" transform="rotate(-34.5432 18.361 18.4382)" fill="#70C1FF" />
            <path
              d="M24.8484 18.9838C25.1545 18.6778 25.1545 18.1816 24.8484 17.8756L19.8614 12.8886C19.5554 12.5825 19.0592 12.5825 18.7532 12.8886C18.4471 13.1946 18.4471 13.6908 18.7532 13.9968L23.1861 18.4297L18.7532 22.8626C18.4471 23.1686 18.4471 23.6648 18.7532 23.9708C19.0592 24.2768 19.5554 24.2768 19.8614 23.9708L24.8484 18.9838ZM12.4297 19.2133L24.2943 19.2133L24.2943 17.6461L12.4297 17.6461L12.4297 19.2133Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.45785" cy="8.82455" r="6.28665" transform="rotate(-34.5432 9.45785 8.82455)" fill="#70C1FF" />
            <path
              d="M12.7016 9.10128C12.8546 8.94826 12.8546 8.70018 12.7016 8.54716L10.208 6.05365C10.055 5.90064 9.80695 5.90064 9.65393 6.05365C9.50092 6.20667 9.50092 6.45475 9.65393 6.60777L11.8704 8.82422L9.65393 11.0407C9.50092 11.1937 9.50092 11.4418 9.65393 11.5948C9.80695 11.7478 10.055 11.7478 10.208 11.5948L12.7016 9.10128ZM6.49219 9.21604L12.4245 9.21604L12.4245 8.4324L6.49219 8.4324L6.49219 9.21604Z"
              fill="white"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="37" height="38" fill="none">
            <circle cx="18.206" cy="19.079" r="13.091" fill="#70C1FF" transform="rotate(-34.543 18.206 19.079)" />
            <path
              fill="#fff"
              d="M24.957 19.656a.816.816 0 0 0 0-1.154l-5.192-5.192a.816.816 0 1 0-1.154 1.154l4.616 4.615-4.616 4.616a.816.816 0 0 0 1.154 1.153l5.192-5.192Zm-12.93.239h12.354v-1.632H12.027v1.632Z"
            />
          </svg>
        </router-link>
      </div>
      <h4 class="coaches__name">{{ coach.name }}</h4>
      <div class="coaches__rating">
        <div class="coaches__rating-stars">
          <svg
            v-if="screenWidth > 1686"
            v-for="(star, index) in 5"
            :key="index"
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
              fill="#252C37"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            v-for="(star, index) in 5"
            :key="index"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21869 0.125L6.44912 3.91187L10.4309 3.91187L7.20957 6.25228L8.43999 10.0392L5.21869 7.69874L1.99739 10.0392L3.22781 6.25228L0.00651169 3.91187L3.98826 3.91187L5.21869 0.125Z"
              fill="#252C37"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            v-for="(star, index) in 5"
            :key="index"
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
              fill="#252C37"
            />
          </svg>
        </div>
        {{ coach.rating }}
      </div>
      <button class="coaches__signUp-btn ms_booking" :data-url="coach.idYclientsForm" v-if="addBtn">Записаться</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Coaches List Component',

  props: {
    addBtn: Boolean,
    screenWidth: Number,
  },

  mounted() {
    this.$store.dispatch('fetchCoaches').then(() => {
      // Проверка наличия данных
      if (this.coaches && this.coaches.length) {
        this.coaches.forEach((el) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://${el.idYclients}.yclients.com/widgetJS`;
          script.charset = 'UTF-8';
          script.crossOrigin = 'anonymous';
          document.body.appendChild(script);
        });
      } else {
        console.warn('Нет доступных тренеров.');
      }
    });
  },

  computed: {
    ...mapState({
      coaches: (state) => state.coaches.coaches,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
