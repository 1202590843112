<template>
  <article class="events__card">
    <div class="events__card-hover">
      <img
        class="events__card-img"
        :src="`https://samokat-school.chickenkiller.com/images/Events/${event.img}`"
        alt="фотография события"
      />

      <div class="events__card-hover-link">
        <router-link :to="event.url">
          читать далее
          <svg v-if="screenWidth > 1686" width="186" height="16" fill="none">
            <path
              fill="#fff"
              d="M185.707 8.707a1 1 0 0 0 0-1.414L179.343.929a1 1 0 1 0-1.414 1.414L183.586 8l-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364ZM0 9h185V7H0v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 640"
            width="54"
            height="19"
            viewBox="0 0 54 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.928 0.589844L41.7796 3.73862L45.4686 7.42809L0.271484 7.42809V11.8809L45.4686 11.8809L41.7796 15.5704L44.928 18.7191L53.9915 9.65449L44.928 0.589844Z"
              fill="#F1F1F1"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="160"
            height="16"
            viewBox="0 0 186 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M185.707 8.70711C186.098 8.31658 186.098 7.68342 185.707 7.29289L179.343 0.928932C178.953 0.538408 178.319 0.538408 177.929 0.928932C177.538 1.31946 177.538 1.95262 177.929 2.34315L183.586 8L177.929 13.6569C177.538 14.0474 177.538 14.6805 177.929 15.0711C178.319 15.4616 178.953 15.4616 179.343 15.0711L185.707 8.70711ZM0 9H185V7H0V9Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <span class="events__card-date">{{ event.date }}</span>
    <h3 class="events__card-title">{{ event.title }}</h3>
    <p class="events__card-text">{{ event.text }}</p>
  </article>
</template>

<script>
export default {
  props: {
    event: Object,
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped></style>
