<template>
  <div v-if="topSlides.length > 0">
    <swiper :navigation="true" :modules="modules" :loop="true" class="mySwiper">
      <swiper-slide v-for="(slide, index) in topSlides" :key="slide.id">
        <img :src="`https://samokat-school.chickenkiller.com/images/TopBanner/${slide.img}`" alt="Banner" />
      </swiper-slide>
    </swiper>
  </div>
  <div v-else>
    <p>Loading slides...</p>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      topSlides: (state) => state.topBanner.topSlides,
    }),
  },

  mounted() {
    // Загружаем данные
    this.$store.dispatch('fetchTopSlides').then(() => {});
  },
  watch: {
    topSlides(newValue) {},
  },
};
</script>

<!-- <style lang="scss" scoped></style> -->
