<template>
  <swiper
    :navigation="{
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    }"
    :modules="modules"
    :slidesPerView="3.5"
    :centeredSlides="false"
    :spaceBetween="15"
    :grabCursor="true"
    class="mySwiper CoachPage__swiper"
  >
    <swiper-slide v-for="(photo, index) in coachesPhotos" :key="index">
      <img :src="`https://samokat-school.chickenkiller.com/images/Coaches/${photo}`" alt="Картинка" />
    </swiper-slide>
    <div class="CoachPage__navigation">
      <div class="swiper-button-prev CoachPage__navigation-prev"></div>
      <div class="swiper-button-next CoachPage__navigation-next"></div>
    </div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    screenWidth: Number,
    coachesPhotos: Array,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      photos: (state) => state.aboutParkPhotos.photos,
    }),
  },

  mounted() {
    this.$store.dispatch('fetchCoaches');
  },
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';

.CoachPage__swiper {
  width: 100%;
  height: 410px;

  .swiper-slide {
    height: 350px;
    min-width: 540px;
  }
}

.CoachPage__navigation {
  position: absolute;
  bottom: 25px;
  right: 0;
  width: 350px;

  &-prev::after {
    content: url('@/assets/img/FeedbackSlider/arrowLeft.svg') !important;
  }

  &-next::after {
    content: url('@/assets/img/FeedbackSlider/arrowRight.svg') !important;
  }
}

@media (max-width: 1686px) and (min-width: 1356px) {
  .CoachPage {
    .swiper {
      height: 430px;
    }
    &__swiper {
      height: 430px !important;
      position: relative;

      .swiper-wrapper {
        align-items: center;
        height: 360px;
      }

      .swiper-slide {
        height: 360px;
        min-width: 470px;
      }
    }

    &__navigation {
      position: absolute;
      bottom: 30px;
      right: -30px;
      width: 230px;
      &-prev {
        &::after {
          content: url('@/assets/img/FeedbackSlider/arrowLeft.svg') !important;
        }
      }

      &-next {
        &::after {
          content: url('@/assets/img/FeedbackSlider/arrowRight.svg') !important;
        }
      }
    }
  }
}

@media (max-width: 1355px) and (min-width: 640px) {
  .CoachPage {
    .swiper {
      height: 230px;
    }
    &__swiper {
      height: 230px !important;
      position: relative;

      .swiper-wrapper {
        align-items: center;
        height: 180px;
      }

      .swiper-slide {
        height: 180px;
        min-width: 230px;
      }
    }

    &__navigation {
      position: absolute;
      bottom: 30px;
      right: -30px;
      width: 230px;
      &-prev {
        &::after {
          content: url('@/assets/img/FeedbackSlider/ArrowLeftTablet.svg') !important;
        }
      }

      &-next {
        &::after {
          content: url('@/assets/img/FeedbackSlider/ArrowRightTablet.svg') !important;
        }
      }
    }
  }
}
</style>
