<template>
  <swiper
    :navigation="{
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    }"
    :modules="modules"
    :slidesPerView="screenWidth > 640 ? 3.5 : 1"
    :centeredSlides="false"
    :spaceBetween="15"
    :grabCursor="true"
    class="mySwiper events__swiper"
  >
    <swiper-slide v-for="eventCard in events" :key="eventCard.id">
      <article class="events__card">
        <div class="events__card-hover">
          <img
            class="events__card-img"
            :src="`https://samokat-school.chickenkiller.com/images/Events/${eventCard.img}`"
            alt="фотография события"
          />
          <div class="events__card-hover-link">
            <router-link :to="eventCard.url">
              читать далее
              <svg v-if="screenWidth > 640" width="186" height="16" fill="none">
                <path
                  fill="#fff"
                  d="M185.707 8.707a1 1 0 0 0 0-1.414L179.343.929a1 1 0 1 0-1.414 1.414L183.586 8l-5.657 5.657a1 1 0 1 0 1.414 1.414l6.364-6.364ZM0 9h185V7H0v2Z"
                />
              </svg>
              <svg
                v-if="screenWidth <= 430"
                width="160"
                height="16"
                viewBox="0 0 186 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M185.707 8.70711C186.098 8.31658 186.098 7.68342 185.707 7.29289L179.343 0.928932C178.953 0.538408 178.319 0.538408 177.929 0.928932C177.538 1.31946 177.538 1.95262 177.929 2.34315L183.586 8L177.929 13.6569C177.538 14.0474 177.538 14.6805 177.929 15.0711C178.319 15.4616 178.953 15.4616 179.343 15.0711L185.707 8.70711ZM0 9H185V7H0V9Z"
                  fill="white"
                />
              </svg>
            </router-link>
          </div>
        </div>
        <div class="events__card-description">
          <span class="events__card-date">{{ eventCard.date }}</span>
          <h3 class="events__card-title">{{ eventCard.title }}</h3>
          <p class="events__card-text">{{ eventCard.text }}</p>
        </div>
      </article>
    </swiper-slide>
    <div class="events__navigation">
      <div class="swiper-button-prev events__navigation-prev"></div>
      <div class="swiper-button-next events__navigation-next"></div>
    </div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
  props: {
    screenWidth: Number,
    events: Array,
  },

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      eventsCards: (state) => state.eventsCards.eventsCards,
    }),
  },
  mounted() {
    this.$store.dispatch('eventsData');
  },
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';
</style>
